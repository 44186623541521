.avatar {
  width: 68px;
  min-width: 68px;
  height: 68px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #03152F;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .icon {
    width: 32px;
    height: 32px;

    path {
      fill: #ACCAF8
    }
  }
}
