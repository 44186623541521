.profile-page, .profile-page-edit {
  background: url('../../assets/images/account-bg.png');
  background-repeat: no-repeat;
  background-position: center;
}

.link-boxes {
  .link-box {
    position: relative;
    > div {
      background: rgba(1, 21, 51, 0.9);
      &:focus-visible {
        outline: none;
      }
    }

    svg path {
      fill: #FFF;
    }

    a:hover {
      color: #fff !important;
      text-decoration: none;
    }

    &:focus-visible {
      outline: none;
    }

    &:not(.disabled):not(.box-subscription) {
      &::after {
        content: '';
        display: block;
        width: 36px;
        height: 36px;
        position: absolute;
        top: 28px;
        left: 50%;
        transform: translateX(-50%);
        background: #ACCAF8;
        opacity: 0.15;
        filter: blur(12.5px);
      }

      &.is-expanded:after {
        top: 17px;
        transform: translateX(-100%);
      }
    }

    &:not(.disabled):hover {
      opacity: 1;

      > div {
        background: rgb(1, 21, 51);
      }
    }
    &.disabled {
      cursor: not-allowed;
      svg {
        opacity: .3;
        cursor: not-allowed;
      }
      &:hover {
        opacity: .9;
      }
    }

    &.box-subscription {}
  }
}

.account-name {
  max-width: 930px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: medium;

  a {
    font-size: 14px;
    color: #feed00;
  }
}
.account-blur-bg {
  background-image: url('../../assets/illustrations/v2/blur_background_2.png') !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.edit-btn {
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
  background-color: rgba(1, 21, 51, 0.9) !important;


  svg {
    width: 24px !important;
    height: 24px !important;
  }

  &:hover {
    svg path {
      fill: #FFFFFF !important;
    }
  }
}

.field-link {
  padding: 12px 16px;
  background-color: rgba(1, 21, 51, 0.9);
  border-radius: 8px;
  opacity: .8;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 0;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-between;

  &__label,
  &__value {
    font-size: 16px;
    line-height: 24px;
  }

  &__label {
    color: #ACCAF8;
    text-decoration: none;
    font-weight: 400;
  }

  &__value {
    @extend .flex-fill;
    color: #CFE1FB;
    text-decoration: none;
    font-weight: 500;
    margin-top: -12px;
    margin-bottom: -12px;
    min-height: 50px;
    cursor: pointer;
  }

  .field-link__value {
    @extend .d-flex;
    @extend .justify-content-end ;
    @extend .align-items-center;
  }

  &:hover {
    opacity: .9;
    background-color: rgb(1, 21, 51);

    .field-link__value {
      color: #FFFFFF;
    }
  }

  &__empty {
    .field-link__value {
      color: #FEED00;
    }

    &:hover {
      .field-link__value {
        color: #FEED00;
      }
    }
  }

  input, select {
    border: none;
    background-color: transparent;
    text-align: right;
    outline: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #CFE1FB !important;

    @include placeholder() {
      color: #ACCAF8 !important;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    &[type="date"] {
      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }
  }
}

.phone-field {
  position: relative;

  &__input {
    background-color: #E7F0FD;
    border: 2px solid #ACCAF8;
    border-radius: 8px;
    padding: 10px 14px;
    font-size: 16px;
    line-height: 24px;
    color: #020A17;
    outline: none;

    &:focus {
      border-color: #3099dc;
    }
  }

  &__button {
    background: none;
    @extend .d-inline-flex;
    padding: 0;
    border: none;
    position: absolute;
    bottom: 13px;
    right: 13px;

    path {
      transition: 150ms ease-in-out;

      &[opacity] {
        fill: #051F46;
        opacity: .15
      }

      &[fill-rule] {
        fill: #051F46;
      }
    }

    &:hover path[opacity] {
      opacity: .2;
    }
  }
}

.info-box {
  width: 100%;
  max-width: 512px;
  padding: 12px 16px;
  background: rgba(1, 21, 51, 0.9);
  box-shadow: inset 0px 0.5px 0px #001F4D;
  backdrop-filter: blur(5px);
  border-radius: 16px 16px 16px 0px;
}

.gender-drop-menu {
  .dropdown-menu {
    padding: 0;
    background-color: rgba(1, 21, 51, 1);
    border: 1px solid #36455e;
    .dropdown-option {
      &:hover {
        opacity: 0.9;
        background-color: #011533;
      }
    }
    .dropdown-divider {
      border-color: #36455e;
    }
  }
}
