.payment-block {
  background: #1d2047;
  max-width: 440px;
  min-width: 440px;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 auto;
  padding-bottom: 30px;

  .methods-block {
    height: 72px;
    background: #16193C;
    border-bottom: 2px solid transparent;
    padding: 0 20px;
    button {
      border-radius: 0;
      padding: 10px 10px 17px;
      img {
        opacity: 0.6;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &.active {
        border-bottom: 2px solid #FEED00;
        img {
          opacity: 1;
        }
      }
    }
  }

  p {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: #6B82A4;
  }

  .button-block {
    padding: 0 40px;
    button.btn-outline-info {
      color: #BCC9DA;
    }
  }

  .title-block {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    font-size: 22px;
    color: #ffffff;
    height: 74px;
    background: #16193c;
  }

  .footer-block {
    padding-top: 30px;
    p {
      line-height: 18px;
      font-size: 14px;
      color: #8195b3;
      span {
        margin-left: 4px;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .payment-field {
    color: #fff;
    label {
      margin: 0;
      margin-right: 30px;
      white-space: nowrap;
      font-size: 16px;
      color: #6b82a4;
    }
    .StripeElement {
      width: 100%;
      min-width: 70px;
    }
  }

  .field-row {
    padding: 25px 30px;
    border-bottom: 1px solid #090720;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
    max-width: initial;
    min-width: 100%;
    border-radius: 0;

    .methods-block {
      padding: 0;
    }
  }
}

.payment-movie-info {
  padding-bottom: 36px;
  h3 {
    line-height: 28px;
    margin: 16px 0 8px;
    color: #ffffff;
    font-size: 20px;
  }
}
