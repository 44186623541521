@include keyframes(placeholderBox) {
  0% { opacity: 1 }
  50% { opacity: 0.5 }
  100% { opacity: 1 }
}

.placeholder-wrapper {
  .placeholder-box {
    border-radius: 5px;
    &.anim-start {
      @include animation(placeholderBox 1000ms linear infinite);
    }
  }
}
