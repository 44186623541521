.slick-slide {
  &:not(.slick-center) {
    opacity: 0.6;
    @include transition(opacity 1000ms ease-in);
  }
  .banner-image-item {
    position: relative;
    .banner-badges,
    .banner-play-btn {
      @include transition(opacity 1000ms ease-in);
      position: absolute;
      opacity: 0;
    }

    .banner-badges {
      @extend .d-flex;
      @extend .align-items-center;
      top: 24px;
      right: 24px;
    }

    .banner-play-btn {
      left: 28px + 8px;
      bottom: 28px;
      padding: 7px 23px 7px 11px;

      @include media-breakpoint-up(lg) {
        left: 64px + 12px;
        bottom: 64px;
        font-size: 23px;
        line-height: 32px;
        padding: 11px 36px 11px 24px;
      }
    }
  }

  &.slick-active {
    @include transition(opacity 1000ms ease-in);
    opacity: 1;
    .banner-image-item {
      .banner-badges,
      .banner-play-btn {
        @include transition(opacity 1000ms ease-in);
        opacity: 1;
      }
    }
  }
}

.home-posters {
  position: relative;

  .banner-image-item {
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    outline: none;
    padding: 0 8px;

    @include media-breakpoint-up(lg) {
      padding: 0 12px;
    }

    img {
      border-radius: 5px;
    }
  }

  .home-slider {

    .dots-wrapper {
      position: relative;
      max-width: 160px;
      overflow: hidden;
      height: 20px;
      margin: 10px auto 0;
    }

    .slick-dots {
      position: static;
      transition: transform 250ms ease-in-out;
      max-width: 160px;
      display: flex !important;
      justify-content: flex-start;
      overflow: hidden;
      margin: 10px auto 0px;
      li {
        button {
          &::before {
            // content: "●";
            // font-family: initial;
            // font-size: 13px;
            color: #FFFFFF;
            opacity: 0.5;
            transition: 150ms ease-in-out;
          }
        }
        &.slick-active {
          button {
            &::before {
              opacity: 1;
              transform: scale(1.8)
            }
          }
        }
      }
      &.wrapped {
        max-width: initial;
        overflow: initial;
        margin: 0;
      }
    }

    .slick-arrow {
      @extend .d-inline-flex;
      @extend .align-items-center;
      // @extend .justify-content-center;
      // width: 38px;
      // height: 38px;
      z-index: 10;
      background: transparent;
      display: none;

      height: 100%;
      @include media-breakpoint-up(md) {
        display: block;
      }

      svg {
        opacity: 0.5;
        transition: 150ms ease-in-out;
      }

      &:before {
        content: '';
      }

      &:hover svg {
        opacity: 1;
      }
    }
    .slick-prev {
      left: 0;
      @extend .justify-content-end;
      @include media-breakpoint-up(md) {
        width: 95px;
        svg {
          margin-left: 56px;
        }
        // left: 56px;
      }
      @include media-breakpoint-up(lg) {
        width: 117px;
        svg {
          margin-left: 80px;
        }
        // left: 80px;
      }
      @include media-breakpoint-up(xl) {
        width: 180px;
        svg {
          margin-left: 124px;
        }
        // left: 124px;
      }
    }

    .slick-next {
      right: 0;
      @extend .justify-content-start;
      @include media-breakpoint-up(md) {
        width: 95px;
        svg {
          margin-right: 56px;
        }
        // right: 56px;
      }
      @include media-breakpoint-up(lg) {
        width: 117px;
        svg {
          margin-right: 80px;
        }
        // right: 80px;
      }
      @include media-breakpoint-up(xl) {
        width: 180px;
        svg {
          margin-right: 80px;
        }
        // right: 124px;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .home-slider {
      .slick-arrow {
        display: none !important;
      }
    }
    .banner-image-item {
      padding: 0;
      border-radius: 0px;
      img {
        border-radius: 0px;
      }
    }
  }
}

@mixin movie-items-transition {
  // NOTE: Keep the duration synced with JS delay
  transition: 300ms ease-in-out !important;
}

.movie-item-wrapper {
  min-width: 300px;
}

.scroll-container {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  position: relative;
  list-style-type: none;
  margin-bottom: 0;
  @include movie-items-transition;
  padding-left: 16px;

  @include media-breakpoint-up(sm) {
    padding-left: 24px;
  }

  @include media-breakpoint-up(md) {
    padding-left: 64px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  .scroll-space {
    min-width: 54px;
  }

  @include media-breakpoint-down(xs) {
    .scroll-space {
      min-width: 16px;
    }
    &.no-scroll {
      padding-left: 20px;
    }
  }

  &.no-scroll {
    .scroll-item {
      margin-bottom: 10px;
    }
  }

  &:not(.no-scale):hover {
    @include media-breakpoint-up(sm) {
      padding-top: 140px;
      margin-top: -140px;
      padding-bottom: 140px;
      margin-bottom: -140px;
      transition: 0ms !important;
    }
  }

  &.scroll-with-transform {
    overflow: visible !important;
    @include media-breakpoint-down(xs) {
      overflow-x: auto !important;
      overflow-y: hidden !important;
    }
  }
}

.movie-item-wrapper {}

.movie-item {
  position: relative;
  width: 292px;
  height: auto;
  margin: 0 4px;
  @include movie-items-transition;
  outline: none !important;

  &:not(.movie-item__sm)::after {
    @include media-breakpoint-up(sm) {
      content: '';
      display: block;
      height: 48px;
    }
  }

  // @include media-breakpoint-up(sm) {
  //   width: 232px;
  // }

  @include media-breakpoint-up(md) {
    // width: 292px;
  }

  .fade-item {
    opacity: 0;
    @include movie-items-transition;
  }

  .show-item {
    opacity: 1;
    @include movie-items-transition;
  }

  .movie-image {
    // height: 90px;
    height: 164px;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    background-color: $v2-extra-dark;
    outline: none !important;

    @include media-breakpoint-up(sm) {
      // height: 130px;
    }

    @include media-breakpoint-up(md) {
      // height: 164px;
    }
  }
  img.ph-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &.with-border {
    .movie-image img {
      border: 3px solid $primary;
      border-radius: 6px;
    }
  }
  &.watched {
    .movie-image {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(#090720, .2);
        z-index: 1;
      }
    }
    .movie-closed-watch-progress {
      position: absolute;
      bottom: 0;
      height: 2px;
      background: $primary;
    }
  }
  .movie-info {
    background: $v2-extra-dark;
    color: #bcc9da;
    overflow: hidden;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    @include movie-items-transition;
    padding: 6px 4px;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 164px;
      left: 0;
      right: 0;
      padding: 6px 12px;
    }

    @include media-breakpoint-up(md) {
      // top: 164px;
    }

    .movie-title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: 1px;

      .moive-watched-time {
        position: absolute;
        right: 0;
        font-weight: 400;
        font-size: 13px;
        color: #ACCAF8;
        right: 12px;
      }
    }

    .movie-allowance-state {
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      margin-bottom: 0;
    }

    .info span {
      background: rgba(2, 10, 23, 0.7);
      color: #CFE1FB;
      font-weight: 500;

      margin-left: 3px;
      border-radius: 3px;
      padding: 0 4px;
      font-size: 10px;
      line-height: 14px;
    }

    .action-btn {
      width: 30px;
      height: 30px;

      .icon {
        width: 16px;
        height: 16px;
      }
    }

    .action-btns {
      button {
        &.play-icon:hover {
          svg {
            path.background-path {
              fill: #fed100;
            }
          }
        }
        &.my-list-icon:hover {
          svg {
            path.pluse-path, path.check-path {
              fill: #fed100;
            }
            path.pluse-circle, path.check-circle  {
              stroke: #fed100;
            }
          }
        }
      }
    }

    .movie-watch-progress-holder {
      background-color: transparentize(#ACCAF8, .9);
      border-radius: 2px;
      overflow: hidden;
    }

    .movie-watch-progress {
      position: relative;
      height: 2px;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #FEED00;
        border-radius: 2px;
      }
    }

    .movie-watch-current-time {
      font-size: 10px;
      line-height: 13px;
      font-weight: 400;
      color: #ACCAF8;
    }
  }

  .movie-selectable {
    position: absolute;
    right: 10px;
    top: 10px;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      z-index: 10;
      position: absolute;
      top: 0;
      right: 100%;
      height: 20px;
      width: 20px;
      background-color: #eee;
      border: 1px solid #aabad1;
      border-radius: 3px;
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-around;
      svg {
        display: none;
      }
    }
    input:checked ~ .checkmark {
      background: #feed00;
      border-color: #feed00;
      svg {
        display: block;
      }
    }
  }

  &:not(.movie-item__sm) {
    &.scaling {
      border-radius: 6px;
      background-color: $v2-extra-dark;

      @include media-breakpoint-up(sm) {
        position: relative;
      }

      .fade-item {
        @include media-breakpoint-up(sm) {
          opacity: 1;
        }
      }

      .show-item {
        @include media-breakpoint-up(sm) {
          opacity: 0;
        }
      }

      .movie-image {
        @include media-breakpoint-up(sm) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .movie-info {
        @include media-breakpoint-up(sm) {
          background: #030F23;
        }

        .movie-allowance-state {}

        .info span {}

        .action-btn {}

        .movie-watch-progress {}

        .movie-watch-current-time {}
      }
    }

    &.scaling {
      @include media-breakpoint-up(sm) {
        @include animation(scaleCard 300ms linear forwards);
      }
    }
    &.unscaling {
      @include media-breakpoint-up(sm) {
        @include animation(unscaleCard 300ms linear forwards);
      }
    }
  }

  // Sizes
  &__sm {
    width: 96px;
    margin: 0 6px;

    @include media-breakpoint-up(sm) {
      width: 156px;
      margin: 0 8px;
    }

    @include media-breakpoint-up(md) {
      width: 164px;
    }

    .movie-image {
      height: 144px;

      @include media-breakpoint-up(sm) {
        height: 234px;
      }

      @include media-breakpoint-up(md) {
        height: 246px;
      }
    }

    .movie-info {
      position: static;

      .fade-item {
        display: none !important;
      }

      .show-item {
        display: block !important;
      }
    }
  }
}

@include keyframes(scaleCard) {
  0% { z-index: 0 }
  1% { z-index: 10 }
  100% { z-index: 10 }
}

@include keyframes(unscaleCard) {
  0% { z-index: 10 }
  99% { z-index: 10 }
  100% { z-index: 0 }
}

@include keyframes(hoverCard) {
  0% {
    opacity: 1;
    transform: translateY(-19px) scale(0.74);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@include keyframes(hoverCardNoScroll) {
  0% {
    opacity: 1;
    transform: scale(0.74);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@include keyframes(hoverCardInfo) {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.slide-wrapper {
  position: relative;
  .hover-card {
    // transition: all 250ms;
    z-index: 100;
    position: absolute;
    border-radius: 5px;
    @include box-shadow('7px 7px 20px 0px rgba(0,0,0,0.8)');
    &.opened {
      @include animation(hoverCard 250ms 1);
      &.no-scroll {
        @include animation(hoverCardNoScroll 250ms 1);
      }
      .movie-item .movie-info {
        @include animation(hoverCardInfo 500ms 1);
      }
    }
    &.closed {
      @include animation(hoverCard 250ms 1 reverse);
      &.no-scroll {
        @include animation(hoverCardNoScroll 250ms 1 reverse);
      }
    }
  }
  .slide-arrow {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 50%;
    height: 165px;
    width: 64px;
    transform: translateY(-50%);
    margin-top: -24px;
    background: rgba(#020A17, .6);
    text-align: center;
    z-index: 12;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    &.arrow-prev {
      left: 0;
    }
    &.arrow-next {
      right: 0;
    }
  }

  &:not(.no-scale):hover {
    &::before, &::after {
      @include media-breakpoint-up(sm) {
        // content: '';
        // display: block;
        // position: absolute;
        // height: 130px;
        // z-index: 1;
        // left: 0;
        // right: 0;
      }
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &:hover {
    .slide-arrow {
      opacity: 1;
    }
  }
}

.slide-wrapper .hover-card .movie-item {
  @extend .d-flex;
  @extend .flex-column;
  width: 100%;
  height: 100%;
  .movie-image {
    max-height: 225px;
  }
  .movie-info {
    @extend .flex-fill;
    display: block;
  }
  .placeholder-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    max-height: 225px;
  }
  .placeholder-box {
    width: 100% !important;
    height: 100% !important;
  }
  &.with-border {
    border: 3px solid $primary;
    .movie-image img {
      border: none;
      border-radius: 0;
    }
  }
}

.loading-container {
  position: relative;
  min-height: 700px;
}
