.faq-page {
  max-height: calc(100vh - 64px);

  .device-item {
    border-bottom: 1px solid #051F46;
    &:nth-last-child(1) {
      border-bottom: none;
    }
    &:hover {
      color: $primary;
      svg path {
        fill: $primary;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    max-height: initial;
  }
}

.faq-sidebar {
  background: #030F23;
  max-width: 360px;
  overflow-y: auto;

  @include media-breakpoint-down(sm) {
    max-width: initial;
    width: 100%;
    background: url('../../assets/images/account-bg.png');

    &.hide-on-mobile {
      display: none;
    }
  }
}

.faq-content {
  overflow-y: auto;
  .content-box {
    min-width: 640px;
    max-width: 640px;
    background: url('../../assets/images/account-bg.png');
    img:not(.is-icon) {
      width: 100%;
      border-radius: 8px;
      border: 2px solid #ACCAF8;
    }
  }

  @include media-breakpoint-down(sm) {
    display: none !important;
    max-width: initial;
    width: 100%;
    background: url('../../assets/images/account-bg.png');

    .content-box {
      min-width: initial;
      max-width: initial;
      width: 100%;
    }

    &.show-on-mobile {
      display: flex !important;
    }

    .h9-alt {
      font-size: 23px;
    }
  }
}
