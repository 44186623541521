.h1-alt {
  font-size: 150px;
  line-height: 160px;
  font-weight: 700;
  letter-spacing: 1%;
}

.h2-alt {
  font-size: 94px;
  line-height: 104px;
  font-weight: 700;
  letter-spacing: 1%;
}

.h3-alt {
  font-size: 58px;
  line-height: 68px;
  font-weight: 700;
  letter-spacing: 1%;
}

.h4-alt {
  font-size: 46px;
  line-height: 60px;
  font-weight: 400;
}

.h5-alt {
  font-size: 41px;
  line-height: 50px;
  font-weight: 400;
}

.h6-alt {
  font-size: 26px;
  line-height: 36px;
  font-weight: 400;

  @include media-breakpoint-up(sm) {
    font-size: 36px;
    line-height: 46px;
  }
}

.h7-alt {
  font-size: 32px;
  line-height: 42px;
  font-weight: 400;
}

.h8-alt {
  font-size: 29px;
  line-height: 38px;
  font-weight: 400;
}

.h9-alt {
  font-size: 26px;
  line-height: 36px;
  font-weight: 400;
}

.text {
  // Sizes
  &-xl-alt {
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;

    @include media-breakpoint-up(md) {
      font-size: 23px;
      line-height: 32px;
    }
  }

  &-lg-alt {
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
  }

  &-md-alt {
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
  }

  &-sm-alt {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;

    @include media-breakpoint-up(sm) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &-xs-alt {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
  }

  // Behaviors
  &-pre-line {
    white-space: pre-line
  }

  &-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.caption {
  &-lg-alt {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
  }

  &-md-alt {
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
  }

  &-sm-alt {
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
  }
}

