.btn {
  &-icon {
    &:hover img {
      filter: invert(1);
      -webkit-filter: invert(1);
    }
  }
  &-sm {
    line-height: 22px;
    border-radius: 30px;
  }

  &-clean {
    background-color: transparent;

    &:hover {
      background-color: rgba(77,89,149,.06);
    }
  }

  &.btn-primary:hover {
    transition: 200ms all ease-in;
    background: #fed100 !important;
  }

  &.btn-with-icon {
    position: relative;
    font-size: 20px;
    padding: 10px 16px 10px 40px;
    min-width: 136px;
    img, svg {
      position: absolute;
      left: 15px;
      top: 12px;
    }
    span {
      display: inline-block;
    }
    &.small-v {
      font-size: 16px;
      svg {
        width: 12px;
        left: 17px;
        top: 11px;
      }
    }
  }
}

.back-btn {
  background-color: #E3E6ED;
  color: #1A3C6F;
  border: none;
  padding: 4px 13px;

  img {
    width: 7px;
    margin-top: -1px;
    margin-right: 2px;
  }

  &:hover {
    background-color: #ced6e8;
  }
}

.filters {
  .btn {
    border: none;
    border-radius: 0;
    border-bottom: 3px solid transparent;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 16px;
    font-weight: 400;
    color: #28436C;
    opacity: 0.6;
    &.active {
      border-bottom-color: $yellow;
      opacity: 1;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.btn-custom-sm-delete {
  height: 45px;
  font-size: 16px;
  font-weight: 500;
}

.btn-custom-sm-edit, .btn-custom-sm-done {
  height: 34px;
  font-size: 15px;
  line-height: 100%;
  font-weight: 500;
  color: #011533;
  min-width: 100px;
  padding-top: 6px;
}

.btn-social {
  position: relative;
  border-radius: 4px !important;
  height: 40px;
  text-align: center;
  font-size: 16px !important;
  color: #ffffff;
  margin-top: 0 !important;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 0 0 20px;
  &.btn-social-facebook {
    padding: 0 0 0 15px;
    background: #3d6ac3;
  }
  &.btn-social-apple {
    color: #011533 !important;
    background: #ffffff;
    svg {
      height: 70px !important;
      width: 40px;
    }
  }
  &:after {
    content: '';
  }
}

.google-login-container.is-mobile {
  min-width: 300px;
  > div {
    width: 100%;
  }
}
