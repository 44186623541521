.badge {
  // MD 20px (Default)
  padding: 1px 7px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid transparent;

  // Sizes
  &-sm {
    padding: 0 5px;
    font-size: 10px;
    line-height: 14px;
    border-radius: 6px;
  }

  // Types
  &-primary {
    color: #011533
  }

  &-secondary {
    background-color: rgba(231, 240, 253, 0.6);
  }

  &-info {
    color: #FFFFFF;
    background-color: #5990FF;
  }

  &-danger {
    color: #FFFFFF;
    background-color: #F8324D;
  }

  &-alt {
    &-filled, &-outline, &-text {
      font-size: 14px;
      line-height: 20px;
      color: #CFE1FB;
      border-radius: 4px;
      padding: 0 5px;

      &.badge-sm {
        font-size: 13px;
        line-height: 18px;
      }
    }

    &-filled {
      background-color: #03152F;
    }

    &-outline {
      color: #ACCAF8;
      border-color: #ACCAF8;
    }

    &-text {
      background-color: rgba(2, 10, 23, 0.7);
    }
  }
}
