.contact-form {
  transition: bottom 1s;
  position: fixed;
  right: 30px;
  bottom: 60px;
  z-index: 10000;

  .toggle-form-button {
    transition: 0.5s all;
    padding: 0;
    text-align: center;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: #feed00;
    &:focus {
      box-shadow: none;
      outline: none;
    }
    img {
      margin-top: -1px;
    }
  }

  &.to-top {
    bottom: 15px;
  }

  &.active {
    .toggle-form-button {
      background: #1a3c6f;
    }
    .submit-form {
      max-height: calc(100vh - 95px);
      height: inherit;
      overflow: auto;
    }
  }

  .submit-form {
    position: absolute;
    right: 0;
    bottom: 100%;
    height: 0;
    width: 390px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 2px 4px 18px rgba(26, 60, 111, 0.9);
    background: #fff;
    transition: 1s all;
    margin-bottom: 20px;

    .form-header {
      background-color: #1a3c6f;
      padding: 14px 25px;
    }
    .form-body {
      padding: 25px;
      p {
        font-size: 13px;
        color: #1A3C6F;

        &.text-danger {
          font-size: 12px;
          text-align: center;
        }
      }
    }
    label {
      font-size: 12px;
      color: #5C5F63;
      margin-bottom: 0px;
    }
    input, textarea {
      background: #e9edf1;
      border-color: transparent;
      &:focus {
        background:#fff;
        border-color: #4c7fcb;
      }
    }
  }
  @include media-breakpoint-down(md) {
    right: 10px;
    bottom: initial;
    height: 100%;
    top: 100%;
    .submit-form {
      width: 100%;
      min-width: 340px;
      max-width: 390px;
    }
    &.active {
      .submit-form {
        height: calc(100% - 60px);
        .form-body {
          height: calc(100% - 52px);
          overflow: auto;
        }
      }
    }
  }
}
