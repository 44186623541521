#react-confirm-alert {
  .react-confirm-alert-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(2, 10, 23, 0.6);
    backdrop-filter: blur(4px);
    height: 100%;
    z-index: 10000;
  }

  .react-confirm-alert {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .flex-column;
  }

  .confirm-modal {
    width: 376px;
    background: #030F23;
    border: 1px solid $v2-dark;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }
}
