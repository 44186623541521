.subscribe-view-overlay {
  background: rgba(2, 10, 23, 0.6);
  backdrop-filter: blur(4px);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
}

.subscribe-view {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: $v2-extra-dark;
  z-index: 100001;

  @include media-breakpoint-up(sm) {
    width: 496px;
  }

  &__close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 24px;
    left: 16px;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;

    @include media-breakpoint-up(sm) {
      top: 20px;
      left: -60px;
      width: 40px;
      height: 40px;
      border: 1px solid #ACCAF8;
    }

    svg {
      width: 100%;
      height: 100%;

      @include media-breakpoint-up(sm) {
        width: 80%;
        height: 80%;
      }

      path[fill-rule] {
        fill: #ACCAF8;
      }
    }
  }

  &__body {
    height: 100%;
    background: linear-gradient(74deg, #020A17 0%, #020A17 68%, rgba(83, 167, 200, 0.2) 100%)
  }

  &__divider {
    background-color: #020A17;
    height: 4px;
    min-height: 4px;

    &::after {
      @include transition(250ms ease-in);
      content: '';
      display: block;
      height: 100%;
      width: 0;
      background-color: $primary;
    }

    &.fill-33:after {
      @include transition(250ms ease-in);
      width: 33%;
    }

    &.fill-50:after {
      @include transition(250ms ease-in);
      width: 66%;
    }

    &.fill-100:after {
      @include transition(250ms ease-in);
      width: 100%;
    }
  }

  .modal-confirm  {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #030F23;
    box-shadow: inset 0px 0.5px 0px #001F4D;
    border-radius: 24px 24px 0px 0px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: calc(100vh - 326px);
      bottom: 100%;
      background: rgba(2, 10, 23, 0.6);
      backdrop-filter: blur(4px);
    }
  }
}

.subscription {
  &__form {
    .StripeElement {
      width: 100%;
      height: 48px;
      border-radius: 8px;
      border: 2px solid #ACCAF8;
      padding: 10px 14px;
      background-color: #E7F0FD;

      @include media-breakpoint-up(sm) {
        height: 50px;
      }

      &--empty {
        background-color: #ACCAF8;
      }

      &--invalid {
        border-color: #F8324D;
        background-color: #CFE1FB;
      }
    }
  }
}

.subs-plan-item {
  cursor: pointer;
  background: rgba(1, 21, 51, 0.9);
  box-shadow: inset 0px 0.5px 0px #051F46;
  backdrop-filter: blur(2px);
  filter: drop-shadow(0px 12px 30px rgba(46, 15, 94, 0.15));
  border-radius: 16px;

  .price-box {
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(10px);
    min-width: 170px;
  }

  &:hover {
    opacity: 1;
  }

  * {
    z-index: 10;
  }

  &.special {
    position: relative;
    filter: drop-shadow(0px 12px 30px rgba(46, 15, 94, 0.15));
    // background: rgba(2, 10, 23, 0.6);
    &::before {
      content: '';
      filter: drop-shadow(0px 12px 30px rgba(46, 15, 94, 0.15));
      background: url('../../assets/images/plan_bg.jpg');
      background-size: contain;
      backdrop-filter: blur(10px);
      filter: blur(8px);
      -webkit-filter: blur(8px);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-height: 76px;
    }
  }
}
