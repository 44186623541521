$spacer-v2: 2px !default;
$times-v2: 100;

$breakpoints-v2: (
  sm: $bp-sm,
  md: $bp-md,
  lg: $bp-lg,
  xl: $bp-xl,
);

@mixin generateClass($sel, $attr, $inverted: false) {
  @for $i from 0 through $times-v2 {
    .#{$sel}-v2-#{$i} {
      #{$attr}: if($inverted, -$i, $i) * $spacer-v2 !important;
    }
  }
  @each $key, $bp in $breakpoints-v2 {
    @for $i from 0 through $times-v2 {
      .#{$sel}-v2-#{$key}-#{$i} {
        @media (min-width: $bp) {
          #{$attr}: if($inverted, -$i, $i) * $spacer-v2 !important;
        }
      }
    }
  }
}

// Paddings
@mixin paddings {
  @include generateClass('pt', 'padding-top');
  @include generateClass('pb', 'padding-bottom');
  @include generateClass('pl', 'padding-left');
  @include generateClass('pr', 'padding-right');
  @include generateClass('px', 'padding-left');
  @include generateClass('px', 'padding-right');
  @include generateClass('py', 'padding-top');
  @include generateClass('py', 'padding-bottom');
  @include generateClass('p',  'padding');
}

// Margins
@mixin margins {
  @include generateClass('mt', 'margin-top');
  @include generateClass('mb', 'margin-bottom');
  @include generateClass('ml', 'margin-left');
  @include generateClass('mr', 'margin-right');
  @include generateClass('mx', 'margin-left');
  @include generateClass('mx', 'margin-right');
  @include generateClass('my', 'margin-top');
  @include generateClass('my', 'margin-bottom');
  @include generateClass('m',  'margin');
}

// Pulls
@mixin pulls {
  @include generateClass('pull-t', 'margin-top', true);
  @include generateClass('pull-b', 'margin-bottom', true);
  @include generateClass('pull-l', 'margin-left', true);
  @include generateClass('pull-r', 'margin-right', true);
  @include generateClass('pull-x', 'margin-left', true);
  @include generateClass('pull-x', 'margin-right', true);
  @include generateClass('pull-y', 'margin-top', true);
  @include generateClass('pull-y', 'margin-bottom', true);
  @include generateClass('pull', 'margin', true);
}

@include paddings;
@include margins;
@include pulls;
