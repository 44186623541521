.about-page {
  .info-block {
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-center;
    @extend .align-items-center;
    text-align: center;
    font-weight: lighter;
    color: #011533;
    font-size: 20px;
    min-height: 492px;
    background: url('../../assets/images/about-bg.jpg');
    background-size: cover;
    background-position: center;
    padding: 5% 0;

    p {
      max-width: 860px;
      margin: 0;
    }

    h1 {
      font-size: 70px;
      font-weight: bold;
      margin-bottom: 30px;
      line-height: 50px;
    }
  }

  .why-block, .world-block {
    @extend .d-flex;
    @extend .flex-column;
    @extend .align-items-center;
    color: #011533;
    font-size: 15px;
    font-weight: lighter;

    h3 {
      font-size: 40px;
      font-weight: bold;
    }
  }

  .world-block {
    text-align: center;
    background: #f2f2f2;
    p {
      font-size: 18px;
      max-width: 480px;
    }
    img {
      max-width: 1000px;
    }
  }

  .feature-block {
    max-width: 310px;
    h5 {
      min-height: 50px;
    }
  }

  @include media-breakpoint-down(xs) {
    .info-block {
      h3 {
        font-size: 50px;
      }
      p {
        font-size: 19px;
      }
    }

    .why-block, .world-block {
      h3 {
        font-size: 35px;
      }
      p {
        font-size: 15px;
      }
    }
  }
}
