.search-page {
  margin-top: 60px;

  .search-result {
    padding-left: 22px;
    font-size: 16px;
    color: #8195b3;
    margin-bottom: 22px;
    strong {
      font-weight: normal;
      color: #ffffff;
    }
  }

  @include media-breakpoint-down(xs) {
    margin-top: 10px;
    .search-result {
      font-size: 20px;
    }
  }
  .infinite-scroll-component {
    overflow: initial !important;
  }
}
