
@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -ms-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

.loading-bar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  left: 0;

  &:before {
    @include animation(loading-bar .8s ease-out);
    content: " ";
    position: absolute;

    top: 0;
    left: 0;

    height: 4px;
    background: #5867DD;
  }

  > * {
    @include animation(loading-complete 1s ease-out);
  }
}

@include keyframes(loading-bar) {
  0% {
    width: 0%;
  }

  80% {
    width: 100%;
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@include keyframes(loading-complete) {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.spinner {
  @include animation(rotate 2s linear infinite);
  width: 50px;
  height: 50px;
  .path {
    stroke: #5d78ff;
    stroke-linecap: round;
    @include animation(dash 1.5s ease-in-out infinite);
  }
}

@include keyframes(rotate) {
  100% {
      transform: rotate(360deg);
  }
}

@include keyframes(dash) {
  0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
  }
  50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
  }
  100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
  }
}

.btn {
  span {
    transition: 200ms all ease-in;
    margin: 0 3px 0 1px;
  }
  &:hover {
    span {
      transition: 200ms all ease-in;
      margin: 0 0 0 4px;
    }
  }
}
