.device-connect-page {
  min-height: 100vh;
  padding-top: 250px;
  background: url('../../assets/images/account-bg.png');
  background-repeat: no-repeat;
  background-position: center;

  h4 {
    margin-bottom: 15px;
  }

  p {
    font-size: 18px;
    color: #8195b3;
    font-weight: 300;
  }

  .device-block {
    position: relative;
    .back-button {
      position: absolute;
      left: -55px;
    }
  }


  @include media-breakpoint-down(xs) {
    padding-top: 100px;
  }
}


.device-code-form {
  min-height: 56px;
  position: relative;
  .code-loading {
    position: absolute;
    top: 0;
    background: rgba(255,255,255,0.2);
    border-radius: 10px;
  }
  input {
    background: transparent;
    border: none;
    min-width: 50px;
    max-width: 50px;
    text-align: center;
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    caret-color: transparent;
    &:focus {
      box-shadow: none;
      outline: none;
      @include placeholder() {
        color: #fff;
      }
    }
    @include placeholder() {
      color: #ACCAF8;
    }
    &.fetching {
      opacity: 0.3;
    }
  }

  @include media-breakpoint-down(xs) {
    input {
      font-size: 30px;
    }
  }

  &.size-sm {
    input {
      font-size: 20px;
      &.filled {
        font-size: 36px;
        font-weight: 500;
      }
    }
  }
}
