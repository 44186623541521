.custom-slider {
  position: relative;

  &__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    border: none;
    background-color: transparentize(#020A17, .4);
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    &:not(:disabled) {
      cursor: pointer;
    }

    &-left {
      left: 0;
      padding-left: 16px;
      padding-right: 0;
    }

    &-right {
      right: 0;
      padding-right: 16px;
      padding-left: 0;
    }

    svg {
      width: 36px;
      height: 36px;

      path {
        fill: transparentize(#FFFFFF, .5)
      }
    }

    &:hover svg path {
      fill: #FFF;
    }

    &:disabled {
      display: none !important;
    }
  }

  &__content {
    overflow: auto;
    @extend .d-flex;
    @extend .no-scrollbar;
  }

  &.is-dense {
    margin-left: -20px !important;
    margin-right: -20px !important;

    @include media-breakpoint-up(sm) {
      margin-left: -40px !important;
      margin-right: -40px !important;
    }

    .custom-slider__content {
      padding: 0 20px !important;

      @include media-breakpoint-up(sm) {
        padding: 0 40px !important;
      }
    }
  }
}
