// [Global Classes]
.no-list-style {
  list-style: none;
}

.list-style-light__row {
  @extend .d-flex;
  @extend .align-items-center;
  list-style: none;

  li {
    color: $v2-light-alt;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    @extend .d-inline-flex;
    @extend .align-items-center;

    &:not(:first-child):not(.no-dot)::before {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      margin: 0 8px;
      border-radius: 2px;
      background-color: $v2-light-alt
    }
  }
}

.pointer {
  cursor: pointer;
}

.op-1 { opacity: .1 }
.op-2 { opacity: .2 }
.op-3 { opacity: .3 }
.op-4 { opacity: .4 }
.op-5 { opacity: .5 }
.op-6 { opacity: .6 }
.op-7 { opacity: .7 }
.op-8 { opacity: .8 }
.op-9 { opacity: .9 }
.op-10 { opacity: 1 }

.z-1 { z-index: 1 }
.z-2 { z-index: 2 }
.z-3 { z-index: 3 }
.z-4 { z-index: 4 }
.z-5 { z-index: 5 }
.z-6 { z-index: 6 }
.z-7 { z-index: 7 }
.z-8 { z-index: 8 }
.z-9 { z-index: 9 }

.mnw-auto { min-width: auto !important }
.mnw-20 { min-width: 20px }
.mnw-30 { min-width: 30px }
.mnw-80 { min-width: 80px }
.mnw-100 { min-width: 100px }
.mnw-110 { min-width: 110px }

.col-lg-2_5 {
  @include media-breakpoint-only(lg) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.mnh-57 {
  min-height: 57px;
}

.no-decoration {
  &, &:hover {
    text-decoration: none;
  }
}

.no-outline {
  &, &:hover, &:focus, &:active {
    box-shadow: none;
    outline: none;
  }
}

.object-fit {
  &__contain {
    object-fit: contain;
  }
  &__cover {
    object-fit: cover;
  }
}

.object-position {
  &__center {
    object-position: center;
  }
}

.flip {
  &-x {
    @include rotateX(180)
  }

  &-y {
    @include rotateY(180)
  }
}

.fill {
  &-white {
    path:first-child:last-child, path[fill-rule] {
      fill: #FFFFFF !important;
    }
  }

  &-v2-light-med path[clip-rule] {
    fill: $v2-light-med !important;
  }
  &-v2-light-alt path[clip-rule] {
    fill: $v2-light-alt !important;
  }
}

.mw-100p {
  max-width: 100%
}

.scale {
  &-05 {
    transform: scale(0.5);
  }
}
