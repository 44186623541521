@include media-breakpoint-down(md) {
  h1, .h1 {
    font-size: 30px;
    font-weight: 500;
  }

  h2, .h2 {
    font-size: 24px;
  }

  h3, .h3 {
    font-size: 24px;
  }

  h4, .h4 {
    font-size: 18px;
  }

  h5, .h5 {
    font-size: 16px;
  }

  p, .size-p {
    font-size: 14px;
  }

}

h1, h2, h3 {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-content {
  color:#8195B3;
  font-weight: 100;
  ul {
    list-style: none;
    li {
      position: relative;
      margin-bottom: 10px;
      &:before {
        position: absolute;
        content: "•";
        color:#feed00;
        left: -40px;
      }
    }
  }
}

.weight-100 { font-weight: 100 !important }
.weight-200 { font-weight: 200 !important }
.weight-300 { font-weight: 300 !important }
.weight-400, .weight-regular { font-weight: 400 !important }
.weight-500, .weight-medium { font-weight: 500 !important }
.weight-600 { font-weight: 600 !important }
.weight-700, .weight-bold { font-weight: 700 !important }
.weight-800 { font-weight: 800 !important }
.weight-900, .weight-extra-bold { font-weight: 900 !important }

.text {
  &-xxs {
    font-size: 8px;
  }
  &-xs {
    font-size: 10px;
  }
  &-sm {
    font-size: 12px;
    line-height: 25px;
  }

  &-md {
    font-size: 15px;
    line-height: 25px;
  }

  &-lg {
    font-size: 18px;
    line-height: 24px;
  }

  &-primary__light {
    color: $primary-light
  }
  &-grey__light {
    color: $grey-light
  }
  &-light {
    color: $light;
  }
}

.bg {
  &-primary__light {
    background-color: $primary-light
  }
  &-grey__light {
    background-color: $grey-light
  }
  &-purple__light {
    background-color: #E5E6EF;
  }
}
