.dash-header {
  padding: 16px 0;

  ul {
    margin-left: 16px;
    margin-bottom: 0;
    padding-left: 0;

    li {
      margin-left: 32px;
      a {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #B8D1F9;
        position: relative;

        &:hover {
          color: #FFFFFF;
          text-decoration: none;
        }
        &.active {
          color: #FFFFFF;

          &::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            left: calc(50% - 20px/2);
            top: calc(50% - 20px/2);
            background: #FEED00;
            opacity: 0.6;
            filter: blur(18px);
            z-index: 1;
          }
        }
      }
    }
  }

  &.mob-menu {
    background: #090720;
  }

  &.search-open {
    @include media-breakpoint-down(xs) {
      .logo-container {
        display: none !important;
      }
      .search-block-container {
        width: 100%;
      }
      .search-block {
        transition: all 250ms ease-in-out;
        margin-right: 0;
        width: 100%;
        position: static;
      }
      .profile-btn {
        display: none;
      }
    }
  }

  .profile-btn {
    display: block;
    height: 32px;
    width: 32px;
    border-radius: 8px;
    background: #feed00;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .burger-menu-btn {
    min-width: 40px;
    min-height: 40px;
    margin-right: 8px;
    margin-left: -8px;
    &:focus {
      box-shadow: none;
    }
  }
}

.mobile-menu {
  margin-top: 72px;
  padding-top: 15px;
  position: fixed;
  height: calc(100vh - 72px);
  width: 100%;
  left: 0;
  top: 0;
  background: #090720;
  overflow: scroll;
  z-index: 100;

  .lang-sel {
    font-size: 14px;
    color: #8195b3;
    margin-bottom: 40px;
    select {
      background: #19204a;
    }
  }

  .mob-nav {
    li {
      margin-bottom: 30px;
    }
    a {
      font-size: 19px;
      font-weight: 300;
      line-height: 14px;
    }
  }

  .mob-menu-soc {
    margin-bottom: 30px;
    a {
      margin-left: 30px !important;
      margin-right: 30px !important;
    }
  }

  .footer-seccond {
    padding-top: 22px;
    border-top: 1px solid #19204a;
    font-size: 14px;
    color: #8195b3;
  }
}

.mobile-app-ref-container ~ header .mobile-menu {
  margin-top: 72px + 60px;
  height: calc(100vh - 72px - 60px);
}

.search-block {
  width: 32px;
  transition: all 250ms ease-in-out;
  height: 32px;
  border-radius: 3px;
  position: absolute;
  // right: 50px;
  right: 100%;
  margin-right: 35px;

  .toggle-area {
    width: 32px;
    height: 32px;
    padding: 2px 7px;
  }

  @include media-breakpoint-up(sm) {
    // margin-right: 58px;
    // left: 58px;
  }
  @include media-breakpoint-up(md) {
    // margin-right: 66px;
    // left: 66px;
  }

  .search-area {
    transition: all 250ms ease-in-out;
    width: 0;
    overflow: hidden;
    @extend .d-flex;
    flex: 1;
  }
  input {
    border: none;
    background: transparent;
    margin: 0 12px;
    font-size: 15px;
    color: #fff;
    width: calc(100% - 50px);
    @include placeholder {
      color: #8195b3;
      font-size: 15px;
    }
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  &.opened {
    width: 370px;
    border: 1px solid #4c7fcb;
    background: #120f36;
    transition: all 250ms ease-in-out;
    .search-area {
      transition: all 250ms ease-in-out;
    }
  }
}

#wrapper.light {
  .dash-header {
    background: none;
    ul {
      li {
        a {
          color: #011533;
          font-weight: lighter;
        }
      }
    }
    svg #Group_3 {
      path {
        fill: #011533;
      }
    }
    .search-block {
      background: #ffffff;
      border-color: #011533;
      input {
        color: #011533;
      }
      svg {
        path {
          fill: #011533;
        }
      }
    }
    .burger-menu-btn {
      svg {
        path {
          fill: #011533;
        }
      }
    }
  }
}
