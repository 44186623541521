footer {
  .footer-socials {
    list-style: none;
    padding: 0;
    margin: 0;
    @extend .d-flex;
    @extend .align-items-center;

    li {
      @extend .d-inline-flex;

      &:not(:last-child) {
        margin-right: 40px;
      }
    }

    a {
      svg path {
        fill: #CFE1FB;
      }

      &:hover svg path {
        fill: #FFFFFF;
      }
    }
  }

  .text-copyright {
    color: #ACCAF8;
  }

  .footer-navs {
    .nav-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 24px;
      color: #E7F0FD;
    }

    ul {
      margin: 0;
      padding: 0;
      margin-top: -12px;
      list-style: none;

      li {
        margin-top: 12px;

        a, button {
          white-space: pre-wrap;
          color: #CFE1FB;
          text-decoration: none;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;

          &:hover {
            color: #FFFFFF;
          }
        }
      }
    }
  }

  .brand-list {
    margin: -4px;

    .brand-holder {
      padding: 4px;
      text-align: center;
    }
  }
}

.lang-select {
  height: 42px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #ffffff;
  border: none;
  width: 184px;
  border-radius: 8px;
  padding: 9px 6px 9px 12px;
  cursor: pointer
}

.brand-logo {
  &.disabled {
    path {
      fill: #ACCAF8;
      opacity: .6;
    }
  }
}
