input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  font-size: $input-fz;
}

// Might be input or textarea
.form-field-container {
  max-width: 500px;
  margin: 0 auto $input-margin-bottom auto;
}

.form-field {
  select {
    @include calc(width, '100% + 20px');
    padding: 0 60px 0 12px;

    // &:invalid, &.is-empty {
    //   &, &:focus {
    //     color: $placeholder-color;
    //   }
    // }
  }

  input, select, textarea {
    @include placeholder() {
      color: $placeholder-color;
    }

    &~label {
      white-space: nowrap;
      color: $placeholder-color;
      margin: 0;
      display: block;
      text-align-last: left;
      min-width: 0;
      position: absolute;
      top: 15px;
      left: 15px;
      font-size: 15px;
      font-weight: 400;
      @include transition(150ms ease-in-out);
      padding-right: 99px;
      @extend .d-flex;
      @extend .align-items-center;
      cursor: auto;
      span {
        @extend .ml-1;
        display: none;
      }
    }

    &.active~label{
      top: -22px;
      left: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #B8D1F9;
      span {
        display: block;
      }
    }
    &:focus~label {
      top: -22px;
      left: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #B8D1F9;
      width: max-content;
      span {
        display: block;
      }
    }
  }

  textarea {
    display: block;
    min-height: 100px;
    padding: 8px 15px;
  }

  &__select {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -21px;
      width: 20px;
      z-index: 2;
      background-color: $white;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      right: 12px;
      width: 24px;
      height: 24px;
      z-index: 0;
      // background-image: url('../../assets/images/icons/arrow_down_filled.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    select {
      position: relative;
      z-index: 1;
    }
  }

  // &:focus-within {
  //   border-color: $input-focused-border-color;
  // }

  // &.is-error {
  //   border-color: $danger;
  //   label, &:hover label, &:focus label, &:active label, &.active label {
  //     color: $danger !important;
  //   }
  // }
  // &.is-success {
  //   border-color: $success;
  //   label {
  //     color: $success;
  //   }
  // }
}

textarea {
  min-height: 100px;
  resize: none;
}

// CHECKBOX AND RADIO
.checkbox-label {
  border-radius: $input-border-radius;
}
.square,
.circle {
  width: 20px;
  height: 20px;
  border-radius: $input-border-radius;

  @include transition(border-color 200ms, background-color 200ms);
}

.circle {
  &::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transform: scale(0, 0);
    background-color: $primary;

    @include transition(transform 200ms);
  }
}

// CHECKBOX
.checkbox {
  &:checked {
    & + .square {
      background-color: $primary;
      border-color: $primary;
    }

    &:disabled {
      & + .square {
        background-color: $disabled;
      }
    }
  }

  &:disabled {
    & + .square {
      border-color: $disabled;
    }

    & ~ .checkbox-text {
      color: $disabled;
    }
  }
}

// RADIO
.radio {
  &:checked {
    & + .circle {
      border-color: $primary;

      &::before {
        transform: scale(1, 1);
      }
    }
  }
}

.custom-control-input {
  width: 20px;
  height: 20px
}

.custom-control-label {
  &:before, &:after {
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
}

.custom-checkbox .custom-control-label::before {
  border-radius: 3px;
}

.youtube-form {
  position: relative;
  .add-btn {
    height: 40px;
    width: 40px;
    text-align: center;
    border: none;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      background-color: $input-border-color;
      left: 0;
      top: 3px;
      bottom: 3px;
    }

    &:not(:disabled) {
      background-color: #fff;
    }
  }
}

.select-dropdown {
  border-top: 6px solid #5867DD;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.custom-select-box {
  background: #030F23;
  position: relative;
  margin: 0 10px;
  border-radius: 8px;
  svg {
    z-index: 5;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -4px;

    path {
      fill: #B8D1F9
    }
  }

  select {
    position: relative;
    background: transparent;
    z-index: 10;
    -webkit-appearance: none;
    -moz-appearance: none;
    &::-ms-expand {
      display: none;
    }
    &:focus {
      outline: none;
    }
  }
}


.name-input {
  .icon {
    width: 24px;
    height: 24px;
  }

  &__container {
    @extend .d-flex;
    @extend .align-items-center;
    border-bottom: 1px solid #051F46;
    padding-right: 8px;
  }

  &__input {
    @extend .flex-fill;
    background-color: transparent;
    padding: 8px 0;
    border: none;
    font-size: 26px;
    line-height: 36px;
    font-weight: 500;
    color: #FFF;
    outline: none;

    &:focus ~ .icon {
      display: none;
    }
  }
}
