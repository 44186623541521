.accordion {
  ul {
    padding: 0;
    list-style: none;
  }

  .item-header {
    background-color: #19204A;
    transition: all 200ms ease;
    padding: 16px 21px;
    position: relative;
    cursor: pointer;
    font-size: 20px;
    color: #8195B3;
  }

  .item-content {
    transition: all 200ms ease;
    height: 0;
    overflow: hidden;
  }

  .accordion-item {
    max-width: 750px;
    transition: all 200ms ease;
    margin: 0 auto 10px;
    &.active {
      transition: all 200ms ease;
      .item-header {
        transition: all 200ms ease;
        background: #FEED00;
        color: #040217;
        font-size: 22px;
      }
      .item-content {
        font-size: 16px;
        color: #fff;
        transition: all 200ms ease;
        height: 120px;
        overflow: auto;
        padding: 17px 60px 17px 25px;
        text-align: left;
      }
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(xs) {
   .accordion-item {
     &.active {
       .item-content {
         max-height: 500px;
         height: initial;
       }
     }
   }
  }
}
