.movie-player {
  background: #000;
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  video {
    outline: none;
    height: 100%;
  }

  .rotate-screen {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #0a0622;
    z-index: 10000;
  }
}
