.btn-v2 {
  // MD 40px (Default)
  @extend .d-inline-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 7px 14px;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 8px;

  span, &:hover span {
    margin: 0;
  }

  svg + span,
  span + svg {
    margin-left: 8px;
  }

  &:hover {
    svg + span,
    span + svg {
      margin-left: 8px;
    }
  }

  &:focus {
    box-shadow: none !important;
  }

  // Types
  &-primary {
    &, &:hover, &:focus, &:active, &:focus:active {
      color: #011533;
      background-color: #FEED00;

      svg path[clip-rule] {
        fill: #011533;
      }
    }

    &:hover {
      background-color: #E5D500;
    }
  }

  &-secondary {
    &, &:hover, &:focus, &:active, &:focus:active {
      color: #E7F0FD;
      background-color: transparentize(#FFFFFF, .9);
    }

    &:hover {
      background-color: transparentize(#FFFFFF, .85);
    }

    &-alt {
      &, &:hover, &:focus, &:active, &:focus:active {
        color: #ACCAF8;
        background-color: transparentize(#FFFFFF, .9);
      }

      &:hover {
        background-color: transparentize(#FFFFFF, .85);
      }
    }
  }

  &-white {
    color: #011533;
    background-color: #FFFFFF;

    svg path {
      fill: #011533;
    }

    &:hover, &:focus, &:active, &:focus:active {
      color: #011533;
      background-color: #CFE1FB;
    }
  }

  &-outline {
    &, &:hover, &:focus, &:active, &:focus:active {
      color: #E7F0FD;
      border-color: rgba(172, 202, 248, 0.3);
    }
    &:not(:disabled):hover {
      background-color: rgba(231, 240, 253, 0.05);
      border-color: rgba(172, 202, 248, 0.4);
    }

    &-alt {
      &, &:hover, &:focus, &:active, &:focus:active {
        color: #ACCAF8;
        border-color: rgba(172, 202, 248, 0.3);
      }

      &:not(:disabled):hover {
        background-color: rgba(231, 240, 253, 0.05);
        border-color: rgba(172, 202, 248, 0.4);
      }
    }
  }

  // Sizes
  &-xs {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 11px;

    &-alt {
      font-size: 16px;
      line-height: 24px;
      padding: 3px 7px;
    }
  }

  &-sm {
    font-size: 14px;
    line-height: 20px;
  }

  &-lg {
    font-size: 16px;
    line-height: 24px;
    padding: 9px 20px;
  }

  &-xl {
    font-size: 18px;
    line-height: 26px;
    padding: 10px 23px;
  }

  &-xxl {
    font-size: 23px;
    line-height: 32px;
    padding: 11px 24px;
  }

  &-xxxl {
    font-size: 23px;
    line-height: 32px;
    padding: 16px 24px;
  }

  // Variants
  &-circle {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 50%;
    padding: 0;
    @extend .d-inline-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @include transition(150ms ease-in-out);

    .icon {
      width: 24px;
      height: 24px;

      path {
        fill: #FFFFFF;
      }
    }

    // Circle Media Button Variants
    &.media {
      &-toggle {
        background-color: #03152F;

        .icon {
          width: 18px;
          height: 18px;

          path {
            fill: #FEED00
          }
        }
      }

      &-play {
        background-color: #FFFFFF;

        .icon {
          width: 22px;
          height: 22px;

          path {
            fill: #011533
          }
        }

        &:hover {
          background-color: #FEED00;
        }
      }
    }

    &.outlined-light {
      border-color: #ACCAF8;

      .icon path {
        fill: #ACCAF8;
      }

      &:hover {
        background-color: rgba(231, 240, 253, 0.05);
      }

      &.icon-variant {
        &-white {
          .icon path {
            fill: #FFFFFF;
          }

          &:hover {
            background: #051F46;
            // .icon path {
            //   fill: #FEED00;
            // }
          }
        }

        &-primary {
          .icon path {
            fill: #FEED00;
          }

          &:hover {
            background: #051F46;
            // .icon path {
            //   fill: #ACCAF8;
            // }
          }
        }
      }
    }

    // Circle Button Sizes
    &.btn-v2-lg {
      width: 44px;
      height: 44px;
    }
  }
}

.media-rating-button-group {
  border-radius: 44px;
  background-color: #1c232f;
  overflow: hidden;
  @extend .d-inline-flex;
  @extend .align-items-center;

  button {
    width: 44px;
    height: 44px;
    background-color: transparent;
    border: none;
    padding: 0;
    border-radius: 0;
    @extend .d-inline-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    transition: 0ms;

    &:disabled {
      opacity: 1;
    }

    .icon {
      width: 24px;
      height: 24px;
      transition: 0ms;

      &:last-child {
        display: none;
      }

      path {
        fill: #FFFFFF;
        transition: 0ms;
      }
    }


    &:not(:disabled):hover, &.active {
      background-color: #FEED00;

      .icon {
        path {
          fill: #000000;
        }

        &:last-child {
          display: block;
        }

        &:first-child {
          display: none;
        }
      }
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.rating-buttons {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 30%;
  transition: 200ms all ease-in-out;
  width: 45px;

  .rating-standalone {
    transition: 200ms all ease-in-out;
    margin-left: 0;
    &.active {
      &, &:hover, &:focus, &:active, &:focus:active {
        background-color: #FEED00;
        border-color: #FEED00;

        svg path[fill-rule] {
          fill: #000000;
          transition: 0ms;
        }
      }
    }
  }

  .button-group-holder {
    transition: 200ms all ease-in-out;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    opacity: 0;
    z-index: -1;
  }

  &:hover {
    transition: 200ms all ease-in-out;
    width: 90px;
    .rating-standalone {
      transition: 200ms all ease-in-out;
      opacity: 0;
      margin-left: 5px;
    }
    .button-group-holder {
      transition: 200ms all ease-in-out;
      z-index: 1;
      opacity: 1;
    }
    &.is-small {
      width: 60px;
    }
  }

  &.opens-left {
    .button-group-holder {
      left: unset;
      right: 0;
    }
  }
}
