.vjs-theme-city {
  --vjs-theme-city--primary: #feed00;
  --vjs-theme-city--secondary: #fff;
}

.vjs-theme-city  {

  .vjs-progress-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 20px;

    .vjs-progress-holder {
      position: absolute;
      top: 20px;
      height: .4em;
      right: 93px;
      left: 20px;
      margin: 0;
    }

    .vjs-play-progress {
      background-color: var(--vjs-theme-city--primary);
      border-radius: 5px;
      &::before {
        transition: all 500ms ease !important;
        opacity: 0;
        color: #feed00;
        width: 24px;
        height: 24px;
        top: -11px;
        line-height: 1.3em;
        text-align: center;
        background: #feed00;
        border-radius: 100%;
        font-size: 20px;
      }
    }

    .vjs-control {
      .vjs-slider.vjs-slider-horizontal {
        border-radius: 2px;
        background: rgba(172, 202, 248, 0.3);
      }
      .vjs-load-progress div {
        border-radius: 2px;
        background: rgba(172, 202, 248, 0.3);
      }
    }

    &:hover {
      .vjs-progress-holder {
        font-size: 1em;
      }
      .vjs-play-progress.vjs-slider-bar {
        &:after {
          display: none;
        }
      }
      .vjs-time-tooltip {
        font-size: 1.3em;
        height: 2em;
      }
      .vjs-mouse-display:after {
        display: none;
      }

      .vjs-play-progress {
        &::before {
          transition: all 500ms ease !important;
          opacity: 1;
        }
      }
    }
  }

  .vjs-control-bar {
    position: fixed;
    height: 148px;
    padding: 48px 20px 0px;
    background: none;
    bottom: 0;
    left: 0;
    right: 0;

    &.has-sprites {
      .vjs-progress-control {
        &:hover {
          .vjs-mouse-display {
            .vjs-time-tooltip {
              border: 2px solid #ACCAF8;
              border-radius: 4px;
              transform: translateY(-50px);
              line-height: 245px;
            }
          }
        }
      }
    }
  }

  .vjs-volume-panel {
    height: 3em;
    @extend .d-flex;
    @extend .align-items-start;

    .vjs-volume-control {
      opacity: 1;
      transition: none !important;
    }
    .vjs-volume-horizontal {
      transition: none !important;
      width: 4em !important;
      min-width: 70px;
      height: 3em;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 18px;
      margin-left: 20px;
    }
    &.vjs-hover {
      height: 3em !important;
      .vjs-volume-horizontal {
        height: 3em !important;
      }
    }
  }

  .vjs-volume-bar {
    margin-left: 1em;
    width: 8em;
    border-radius: 10px;
    height: .4em;
    background: rgba(#bcc9da, 0.5) !important;
  }

  .vjs-slider-horizontal .vjs-volume-level {
    height: .4em;
    background: #feed00;
    border-radius: 5px;
    &:before {
      font-size: 1.6em;
      top: -0.3em!important;
      color: #feed00;
    }
  }

  .vjs-remaining-time {
    position: absolute !important;
    right: 20px;
    top: 0px;
    font-size: 16px !important;
    line-height: 24px;
    width: initial !important;
    padding: 0;
    margin-top: 9px;
    font-weight: 400;
  }

  .vjs-seek-to-live-control.vjs-control, .vjs-subs-caps-button, .vjs-audio-button, .vjs-playback-rate {
    display: none;
  }

  .vjs-fullscreen-control {
    background-image: url('../../assets/icons/fullScreenOn.svg') !important;
    position: absolute;
    right: 36px;
    span:before {
      content: "" !important;
    }
  }

  &.vjs-fullscreen {
    .vjs-fullscreen-control {
      background-image: url('../../assets/icons/fullScreenOff.svg') !important;
    }
  }

  .vjs-icon-next-item {
    background-image: url('../../assets/icons/nextMovie.svg') !important;
    position: absolute;
    right: 220px;
    &:before, span:before {
      content: "" !important;
    }
  }

  .icon-movie-epsiode {
    position: absolute;
    right: 380px;
  }

  .vjs-control {
    &:before, span:before {
      font-size: 3em;
      line-height: 1.5em;
      text-align: center;
    }
    &:hover {
      &:before, span:before {
        text-shadow: 0 0 1em #fff;
      }
    }
    &.vjs-button {
      margin-top: 12px;
      height: 4em;
    }
  }

  @include media-breakpoint-down(xs) {
    .vjs-control-bar {
      position: absolute;
      padding: 30px 15px 0px;
    }

    .player-episodes-menu {
      position: absolute;
      bottom: 140px;
      .seasons-tabs {
        padding: 0 30px;
      }
      .movie-item-wrapper {
        width: 200px !important;
        height: 124px !important;
        .episode-movie-wrapper  {
          width: 190px !important;
          .episode-movie {
            width: 192px;
            height: 122px;
            .img-area {
              height: 100px;
            }
          }
        }
      }
      .scroll-container {
        padding-left: 30px;
        overflow-y: hidden !important;
      }
    }

    .vjs-button {
      outline: none !important;
    }

    .vjs-button:focus-visible {
      outline: none !important;
    }

    .vjs-volume-control {
      display: none;
    }

    .vjs-progress-control .vjs-progress-holder {
      right: 75px !important;
      left: 20px !important;
    }

    .vjs-remaining-time {
      right: 13px;
    }

    .vjs-fullscreen-control {
      right: 3px;
    }

    .vjs-icon-next-item {
      right: 103px;
    }

    .icon-movie-epsiode {
      right: 143px;
    }
  }

  &.video-in-box {
    .vjs-control-bar {
      position: absolute;
      height: 25px;
      .vjs-picture-in-picture-control, .vjs-volume-panel {
        display: none !important;
      }
      .vjs-progress-control {
        .vjs-progress-holder {
          border-radius: 2px;
          background-color: rgba(#ACCAF8, 0.1);
          right: 16px;
          left: 16px;
        }
      }
    }
  }

  .vjs-play-control {
    @extend .icon-seek;
    background-image: url('../../assets/icons/play.svg') !important;
    width: 4em;
    margin-left: 12px;
    margin-right: 64px;
    span:before {
      content: "" !important;
    }
    &.vjs-paused {
      @extend .icon-seek;
      background-image: url('../../assets/icons/pause.svg') !important;
    }
  }

  .icon-seek {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 100%;
  }


  .icon-play-prev {
    @extend .icon-seek;
    background-image: url('../../assets/icons/playPrev10.svg') !important;
  }

  .icon-play-next {
    margin: 0 40px;
    @extend .icon-seek;
    background-image: url('../../assets/icons/playNext10.svg') !important;
  }

  .vjs-mute-control.vjs-control {
    background-image: url('../../assets/icons/soundon.svg') !important;
    span:before {
      content: "" !important;
    }
    &.vjs-vol-0 {
      background-image: url('../../assets/icons/soundoff.svg') !important;
    }
  }

  .icon-settings {
    @extend .icon-seek;
    background-image: url('../../assets/icons/settings.svg') !important;
    position: absolute;
    height: 37px;
    right: 220px;
    margin-top: 12px !important;
    &.btn-pos-4 {
      right: 300px;
    }
    &:hover, &.active {
      background-image: url('../../assets/icons/settings-active.svg') !important;
    }

    @include media-breakpoint-down(xs) {
      right: 93px;
      &.btn-pos-4 {
        right: 177px;
      }
    }
  }

  .vjs-picture-in-picture-control {
    background-image: url('../../assets/icons/pictureInPicture.svg') !important;
    position: absolute;
    right: 140px;
    span:before {
      content: "" !important;
    }
    @include media-breakpoint-down(xs) {
      right: 53px;
    }
  }

  .icon-movie-epsiode {
    @extend .icon-seek;
    // height: 100% !important;
    // background-position: center 15px !important;
    background-image: url('../../assets/icons/episodes.svg') !important;
  }

  .icon-play-prev, .icon-play-next, .icon-movie-epsiode {
    .vjs-icon-placeholder {
      &::before {
        content: "11";
        color: transparent;
        z-index: 111;
        position: absolute;
      }
    }
    &:hover {
      .vjs-icon-placeholder {
        &::before {
          text-shadow: 0px 0px 1em #fff;
        }
      }
    }
  }
}

.movie-player.episode-menu-open {
  .vjs-theme-city .icon-movie-epsiode {
    background-image: url('../../assets/icons/episodes-active.svg') !important;
  }
  .vjs-control-bar {
    display: none;
  }
}

.vjs-theme-city .vjs-big-play-button {
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  margin-left: -32px;
  margin-top: -32px;
  color: #011533;
  line-height: 64px;
  background: #feed00;
  font-size: 44px;
  border: none;
  &:hover, &:focus-visible, &:focus {
    background: #feed00;
    border: none;
  }
}

.vjs-theme-city {
  &.video-js:hover .vjs-big-play-button, &.video-js .vjs-big-play-button:focus {
    background: #feed00;
    border: none;
  }
}

.player-header {
  z-index: 10000;
  transform: all 1s ease;
  opacity: 0;
  position: fixed;
  top: 0;
  padding: 40px;
  width: 100%;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-between;

  p {
    margin: 0;
    font-size: 26px;
    line-height: 36px;
    font-weight: 500;
    max-width: 720px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include media-breakpoint-down(xs) {
    padding: 0 20px;
    height: 70px;
    &.is-youtube p {
      display: none;
    }
  }
}

.vjs-text-track-display {
  bottom: 0;
  transform: all 1s ease;
}

.vjs-theme-city.vjs-waiting .vjs-big-play-button {
  display: none !important;
}

.vjs-theme-city.video-js {
  &.vjs-user-active, &.vjs-paused {
    .player-header {
      transform: all 1s ease;
      opacity: 1;
    }
    .vjs-text-track-display {
      transform: all 1s ease;
      background-image: linear-gradient(180deg, rgba(2, 10, 23, 0.2) 0%, rgba(2, 10, 23, 0.8) 100%);
    }
  }
  &.vjs-controls-disabled {
    .vjs-text-track-display {
      display: none;
    }
  }
  &.vjs-waiting:not(.vjs-playing) {
    .vjs-control-bar {
      opacity: 0;
    }
  }
}

.ytp-pause-overlay.ytp-scroll-min {
  display: none !important;
}

.player-settings-menu {
  width: 354px;
  height: 520px;
  background: #030F23;
  border-radius: 16px;
  z-index: 10;
  position: fixed;
  right: 63px;
  bottom: 100px;
  font-size: 18px;
  color: #ACCAF8;

  .player-settings-audio-tab {
    width: 100%;
    padding: 36px 30px;

    .menu-section {

      p {
        color: #CFE1FB;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        margin-bottom: 24px;
      }

      ul {
        max-height: 170px;
        overflow: auto;
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
          position: relative;
          margin-bottom: 20px;
          line-height: 26px;
          padding-left: 32px;
          svg {
            position: absolute;
            left: 0;
            path {
              fill: #fff;
            }
          }
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
        li.active {
          color: #fff;
        }
      }

      &.menu-subtitle {
        margin-top: 40px;
      }
      &.no-max-h ul {
        max-height: none;
      }
    }
  }


  @include media-breakpoint-down(xs) {
    width: calc(100% - 40px);
    right: 20px;
    bottom: 90px;
  }
}

.player-ad-information {
  left: 36px;
  bottom: 72px;
  position: absolute;
  z-index: 100000;
  right: 112px;
  p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    font-weight: 600;

    span {
      margin-left: 18px;
    }
  }

  .btn {
    border-radius: 8px;
    font-size: 18px;
    &:hover {
      &.hover-primary {
        background: $primary;
        border-color: $primary;
        color: $black;
      }
    }
  }
}

.player-episodes-menu {
  z-index: 10;
  transform: all 1s ease;
  position: fixed;
  bottom: 100px;
  left: 0;
  width: 100%;

  .seasons-tabs {
    padding: 0 96px;
    margin-bottom: 40px;
    a.btn {
      margin-right: 28px;
      padding: 8px 16px;
      border-radius: 8px;
      font-weight: 500;
    }
  }

  .movie-item-wrapper {
    height: 195px;
    &:focus-visible,
    &:-webkit-direct-focus,
    &:focus {
      outline: none !important;
    }
  }

  .slide-wrapper .slide-arrow {
    height: 167px;
    opacity: 1;
    transform: none;
    top: 28px;
    width: 56px;
  }

  .scroll-container {
    padding-top: 4px;
  }

  .episode-movie-wrapper {
    width: 302px;
    .episode-movie {
      width: 296px;
      height: 195px;

      .img-area {
        position: relative;
        height: 167px;
        border-radius: 4px;
        overflow: hidden;

        .play-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          svg {
            width: 56px;
            height: 56px;
            path:nth-child(1) {
              fill: rgba(#03152F, 0.7)
            }
          }
        }
      }

      .info {
        padding: 6px 10px 0 12px;
      }

      .title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }

       .duration {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      &.current-active {
        .img-area {
          overflow: initial;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            left: -4px;
            top: -4px;
            right: -4px;
            bottom: -3px;
            border: 4px solid #ACCAF8;
            box-sizing: content-box;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.padding-row {
  padding: 0 20px;
}

.ima-ad-container {
  overflow: initial !important;
  background: #000;
  > div:not(.ima-controls-div) {
    // height: initial !important;
    // bottom: 122px;
    // top: 122px;
    // // left: 85px;
    // // right: 85px;
    // // width: initial !important;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  iframe {
    display: none !important;
  }

  .ima-slider-div, .ima-fullscreen-div, .ima-play-pause-div {
    display: none;
  }

  .ima-controls-div {
    // background: #000;
    overflow: initial !important;
    height: 122px;
    padding: 0 50px;
    top: calc(100% - 122px);
    @extend .d-flex;
    @extend .align-items-center;

    > div {
      z-index: 2;
    }

    .ima-mute-div {
      right: 36px;
      font-size: 42px;
      line-height: 42px;
      width: 40px;
      height: 40px;
      top: 5px;
      &:before {
        color: #fff;
      }
    }

    .ima-countdown-div {
      display: none !important;
      // font-size: 20px;
      // position: absolute;
      // left: 95px;
      // top: -34px;
    }

    .ima-seek-bar-div {
      // display: none !important;
      width: initial !important;
      bottom: 46px;
      top: initial;
      left: 36px;
      right: 36px;
      height: 4px;
      background: rgba(#ACCAF8, 0.3);
    }

    &:after {
      // content: "";
      z-index: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 300px;
      width: 100%;
      background: -moz-linear-gradient(top,  rgba(18,15,54,0) 0%, rgba(18,15,54,0.9) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(18,15,54,0) 0%,rgba(18,15,54,0.9) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(18,15,54,0) 0%,rgba(18,15,54,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00120f36', endColorstr='#e6120f36',GradientType=0 ); /* IE6-9 */
    }
  }

  @media (max-width: 900px) {
    background: initial;
    > div:not(.ima-controls-div) {
      left: 0px !important;
      top: 0px !important;
      right: 0px !important;
      bottom: 0 !important;
    }

   .ima-controls-div {
      height: initial !important;
      top: initial !important;
      background: transparent;
      height: 30px !important;
    }

    .ima-mute-div {
      left: -3px !important;
      top: -48px !important;
      font-size: 25px !important;
    }
  }
}

.vjs-loading-spinner {
  background: none;
  border: none;
  font-size: 16px;
  &::before, &:after {
    display: none;
  }
}

.vjs-next-ad-playing .vjs-loading-spinner {
  display: block;
  animation: vjs-spinner-show 0s linear 0.3s forwards;
}

// .player-skip-button {
//   background: #feed00 !important;
//   color: #212529 !important;
//   z-index: 100000;
//   min-width: 120px;
//   height: 45px;
//   position: absolute;
//   right: 50px;
//   bottom: 90px;
//   font-size: 20px !important;
//   opacity: 1 !important;
//   &:disabled {
//     opacity: 0.8 !important;
//   }
//   &:hover {
//     background: #feed00 !important;
//   }

//   @media (max-width: 900px) {
//     right: 10px;
//     bottom: 33px;
//   }
// }
