
.form-group {
  position: relative;
}

input.form-control {
  position: relative;
  &~.eye-icon {
    position: absolute;
    right: 22px;
    top: 20px;
    opacity: 0.6;
    cursor: pointer;
    &:hover {
      opacity: 1
    }
  }
  &.active {
    background: #fff;
  }
}

.input-error {
  position: absolute;
  right: 0;
  font-size: 14px;
  color: $red;
  margin: 10px 0 0 0;
  padding: 0 5px;
  img {
    margin-right: 8px;
  }
}

.text-elipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  overflow: -moz-scrollbars-none;
  display: none;
}

.dob-account-edit {
  input {
    max-width: 200px;
  }
}

.border-transparent {
  border-color: transparent !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

#credential_picker_container {
  display: none !important;
}

.min-w-24 {
  min-width: 24px;
}

.cursor-disabled {
  cursor: not-allowed;
  * {
    cursor: not-allowed;
  }
}

.MuiSnackbar-root {
  z-index: 140000 !important;
  overflow: hidden !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.ms-track {
  border: none;
  &.ms-active {
    border: none;
    &:hover {
      background: none;
      border: none;
      .ms-thumb {
        width: 4px;
        border-radius: 2px;
      }
    }
  }
  &:hover {
    background: none;
    border: none;
    .ms-thumb {
      width: 4px !important;
      border-radius: 2px;
    }
  }
  .ms-thumb {
    right: 5px !important;
    background: #051F46;
    border-radius: 2px;
    width: 4px !important;
  }
  &.ms-y {
    border: none !important;
  }
}

.mxw-250 {
  max-width: 250px;
}

.unsubscribe-page {
  position: relative;
  background-image: url('../assets/images/home.jpg');
  background-size: cover;
  min-height: 100vh;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
  .unsubscribe-box {
    width: 460px;
    background: #020A17;
    .p.info {
      color: #C2D3ED;
    }
  }
}


