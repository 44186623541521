@mixin sizes {
  $size-v2: 1px !default;
  $times-v2: 200;

  @for $i from 0 through $times-v2 {
    .w-v2-#{$i} {
      width: $i * $size-v2 !important;
    }
    .mw-v2-#{$i} {
      min-width: $i * $size-v2 !important;
    }
    .mxw-v2-#{$i} {
      max-width: $i * $size-v2 !important;
    }
    .h-v2-#{$i} {
      height: $i * $size-v2 !important;
    }
    .mh-v2-#{$i} {
      min-height: $i * $size-v2 !important;
    }
    .mxh-v2-#{$i} {
      max-height: $i * $size-v2 !important;
    }
  }
}

@include sizes;
