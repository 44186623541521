@charset "UTF-8";
@import "mixins/main";

@import "utils/main";

@import "~bootstrap/scss/bootstrap.scss";
@import "libs/video.min.css";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~videojs-contrib-ads/dist/videojs.ads.css";
@import "~videojs-ima/dist/videojs.ima.css";
@import '~mac-scrollbar/dist/mac-scrollbar.css';

@import "fonts/main";

@import "reboot";

@import "common/main";

@import "layouts/main";

@import "pages/main";

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex: 1;
  }
}

#wrapper {
  background: $v2-extra-dark;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.is-play {
    position: fixed;
    width: 100%;
  }

  &.light {
    background: #ffffff;
  }
}

a {
  color: #fff;
  &:hover {
    color: #feed00;
  }
}

body {
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  max-width: 100%;
  background-color: $v2-extra-dark;
  @include font-smoothing(antialiased);

  &.mob-menu {
    width: 100%;
    position: fixed;
  }
  // &.ReactModal__Body--open {
  //   overflow: hidden;
  //   position: fixed;
  //   width: 100%;
  // }
  &.has-modal, &.react-confirm-alert-body-element {
    // width: 100%;
    // position: fixed;
    overflow: hidden;
  }
}

.birth-date-form {
  max-width: 500px;
  margin: 0 auto 10px;
  position: relative;
  label {
    left: 0;
    top: -22px;
    position: absolute;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #B8D1F9;
  }
  > div {
    border: 1px solid #4c7fcb;
    border-radius: $input-border-radius;
    overflow: hidden;
  }
  .form-group {
    min-width: 33%;
    margin: 0;
    background-color: #becee6;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 11px;
      right: 12px;
      width: 12px;
      height: 24px;
      z-index: 2;
      background-image: url('../assets/images/arrow.png');
      background-repeat: no-repeat;
      background-position: center;
    }
    select {
      background: transparent;
      font-size: 15px;
      border-radius: 0;
      border: none;
      margin-top: -0.1px;
      padding-left: 10px;
      position: relative;
      z-index: 3;
      -moz-appearance: none;
	    -webkit-appearance: none;
      appearance: none;
    }
    &.selected {
      background: #fff;
    }
    &.non-selected {
      select {
        font-size: 15px;
        color: rgba(14, 31, 63, 0.38);
      }
    }
    &:nth-child(2) {
      border-left: 1px solid #4c7fcb;
      border-right: 1px solid #4c7fcb;
    }
  }
}

.pointer {
  cursor: pointer;
}

.fz-12 {
  font-size: 12px;
}

.text-blue {
  color: #8195b3;
}

.text-extra-light {
  color: $v2-extra-light;
}

a.with-icon-hover:hover {
  svg path {
    fill: $primary;
  }
}

h3.page-title {
  font-size: 25px;
  text-transform: uppercase;
  margin: 0;
  margin-top: 2px;
}

.btn.no-focus:focus {
  box-shadow: none;
}

.with-hover:hover {
  color: $primary;
}

.mobile-suggest-block {
  height: 48px;
  background: #16193c;
  font-size: 13px;
  color: #8195b3;
  padding: 0 20px;
  line-height: 15px;

  .btn-primary {
    height: 28px;
    padding: 0.15rem 0.75rem;
  }
}

.small-language-select {
  border-left: 1px solid #344050;

  .active-option {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    &:hover {
      color: $primary;
    }
  }

  .dropdown-divider {
    border-top: 1px solid #62778c;
  }

  .dropdown-menu {
    padding: 0;
    background: #334150;
    margin-top: 18px !important;
  }

  .dropdown-option {
    cursor: pointer;
    color: #fff;
    &.active, &:hover {
      color: $primary;
    }
  }
}

.large-language-select {
  .active-option {
    min-width: 184px;
    background-color: #030F23;
    padding: 10px 10px 10px 11px;
    border: 1px solid transparent;
    border-radius: 8px;
    color: white;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    @extend .justify-content-between;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;

      path {
        fill: #B8D1F9;
      }
    }
  }

  .dropdown-menu {
    margin-bottom: 12px;
    border-radius: 8px;
    min-width: 100%;
    background-color: #030f23;
  }

  .dropdown.opened {
    .active-option {
      border-color: #051F46;
      svg {
        transform: rotateX(180deg);
      }
    }
  }

  .dropdown-divider {
    display: none;
  }

  .dropdown-option {
    padding: 5px 6px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #CFE1FB;
    background-color: #030f23;
    cursor: pointer;

    &:not(.active):hover {
      color: #FFF;
      background-color: #02152f;
    }

    &.active {
      color: transparentize(#CFE1FB, 0.4)
    }
  }
}

.analytics-debug-btn {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 1000000000;
  font-size: 12px;
  padding: 4px 10px;
  border: none;
}

.MuiSnackbar-root {
  max-width: 500px;
}

.pt-page h1 span.text-primary {
  font-size: 48px !important;
}

.grecaptcha-logo, .grecaptcha-badge {
  display: none !important;
}
