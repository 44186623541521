.movie-view-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#090720, 0.6);
  z-index: 1000;
  overflow-y: auto;
  @extend .d-flex;
  @extend .justify-content-around;
}

.movie-view {
  width: 896px;
  max-width: 100%;
  min-height: 100%;
  margin: 0 auto;
  border-radius: 8px;
  background-color: $v2-extra-dark;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1001;
  outline: none !important;

  @include media-breakpoint-up(sm) {
    min-height: calc(100% - 128px);
    top: 30px;
  }

  &,
  &:focus-visible,
  &:-webkit-direct-focus,
  &:focus {
    outline: none !important;
  }
}

.modal-close {
  padding: 16px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;

  svg {
    width: 24px;
    height: 24px;
  }

  &:focus {
    box-shadow: none;
  }

  &:hover {
    svg path[clip-rule] {
      fill: $v2-light;
    }
  }
}

.movie-view-content {
  .details-banner {
    &.hide-smooth {
      position: absolute;
      top: 0;
      opacity: 0;
      transition: opacity 2s ease-in-out;
    }

    @include media-breakpoint-down(xs) {
      height: 233px;
      overflow: hidden;
    }
  }

  .details-trailer {
    position: relative;
    // Initial
  }
  .details-content {
    padding: 16px 20px 40px;

    @include media-breakpoint-up(sm) {
      padding: 28px 40px 25px;
    }
  }

  .details-trailer {
    // Initial

    .btn-mute-toggle {
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .align-items-center;
      padding: 28px 16px;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 200;

      svg {
        width: 24px;
        height: 24px;
      }

      &:hover {
        svg path {
          fill: $v2-light
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  p.media-title {
    font-size: 14px;
    line-height: 13px;
    color: #ffffff;
    margin-bottom: 15px;
  }

  .detais-actions {
    margin-bottom: 25px;
  }

  .details-watched-progress {
    width: 160px;
    min-width: 160px;

    .watch-progress {
      height: 2px;
      width: 100%;
      background: transparentize($white, .8);
      overflow: hidden;
      border-radius: 5px;
      span {
        display: inherit;
        height: 100%;
        background: #feed00;
      }
    }
  }
}

.episodes-movies,
.related-movies {
  margin: 0 -4px;

  .episode-movie-wrapper,
  .related-movie-wrapper {
    width: 168px;
    min-width: 168px;
    padding: 0 4px;
  }

  .episode-movie,
  .related-movie {
    width: 100% !important;
    height: auto !important;
    position: relative;

    .img-area {
      border-radius: 4px;
      overflow: hidden;
      height: 90px;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .movie-watch-progress {
      position: absolute;
      bottom: 0;
      height: 2px;
      background: $primary;
    }

    .play-btn {
      position: absolute;
      width: 40px;
      height: 40px;
      left: 50%;
      top: 50%;
      transform: translate(-22.5px, -22.5px);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      padding: 6px 8px 0 4px;

      .title {
        @extend .caption-lg-alt;
        font-weight: 700;
        color: #B8D1F9;
      }

      .duration {
        @extend .caption-md-alt;
        font-weight: 700;
        color: #E7F0FD;
      }
    }


    &.current-active {
      .info {
        .title {
          color: #feed00;
        }
      }
    }

    &:hover .info {
      padding-top: 5px;
      padding-bottom: 1px;

      .title {
        color: $primary;
      }
    }
  }
}

.related-movies {
  .movie-item-wrapper {
    .movie-image {
      // border-radius: 4px;
      // overflow: hidden;
      // height: 90px;
      // position: relative;
    }

    .movie-item {
      width: 248px;
      height: 140px;
    }

    &:nth-child(3n + 2) {
      margin: 0 15px;
    }
  }

  &::after {
    content: "";
    flex: auto;
  }
}

.seasons-tabs {
  margin-bottom: 24px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 20px;
  }

  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  button.btn, a.btn {
    &.default-state {
      background: #30396d;
      color: #bcc9da;
    }
    margin-right: 12px;
    &:focus {
      box-shadow: none;
    }
  }
}

.watching-alert {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(top,  rgba(18,15,54,0) 0%, rgba(18,15,54,0.9) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(18,15,54,0) 0%,rgba(18,15,54,0.9) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(18,15,54,0) 0%,rgba(18,15,54,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00120f36', endColorstr='#e6120f36',GradientType=0 ); /* IE6-9 */

  .watching-alert-box {
    background: #03152F;
    border-radius: 15px;
    width: 376px;
    font-size: 16px;
    color: #fff;

    .box-content {
      padding: 24px 24px 32px 24px;
      border-bottom: 1px solid #020A17;
    }

    .box-footer {
      padding: 15px 24px;
    }

    span {
      font-size: 16px;
      line-height: 24px;
      color: #B8D1F9;
    }

    p {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 16px;
      font-weight: 600;
    }
  }
}

.cast-member {
  // TODO: open when we will jave cast crew page
  // cursor: pointer;

  .cast-title,
  .cast-role {
    @include transition(150ms ease-in-out);
  }

  .cast-role {
    color: $v2-light-med
  }

  //TODO: open after cast and crew page
  // &:hover {
  //   .cast-image-holder {
  //     box-shadow: 0 0 0 3px transparentize($v2-light-med, .8)
  //   }

  //   .cast-title {
  //     color: $primary
  //   }

  //   .cast-role {
  //     color: $v2-light
  //   }
  // }
}
