.download-link {
  rect {
    @include transition(150ms ease-in-out);
  }

  &__light, &__light-lg {
    &:hover rect {
      fill: #CFE1FB;
    }
  }

  &__dark, &__dark-lg {
    &:hover path {
      fill: #CFE1FB;
    }
  }
}
