// [Color Palette]
$black: #040217;
$white: #FFFFFF;
$yellow: #FDD100;
$red: #F8324D;
$extra-dark: #18181A;
$v2-extra-dark: #020A17;
$light: #7C92B3;
$v2-light: #CFE1FB;
$v2-light-alt: #B8D1F9;
$v2-light-med: #ACCAF8;
$v2-extra-light: #E7F0FD;
$dark: #15181F;
$v2-dark: #03152F;
$grey-light: #E5E6EF;
$grey-dark: #575757;
$grey: #BEBEBE;

$disabled: #DDDDDD;


$primary: #feed00;
$info: #30396d;
$primary-light: #6883A9;
$danger: $red;

$default-border-color: #D7D9E3;

// [Main Definitions]
$body-bg: #03041a;
$body-color: $white;

// [Typography]
$font-family-base: 'Roboto', sans-serif;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 3 !default;
$h2-font-size:                $font-size-base * 2.625 !default;
$h3-font-size:                $font-size-base * 2.25 !default;
$h4-font-size:                $font-size-base * 1.625 !default;

// [Fields]
$input-margin-bottom:         40px;
$placeholder-color:         rgba(14, 31, 63, 0.38);
$input-fz:                    15px;
$input-lh:                    $line-height-base;
$label-color:               #7C92B3;
$input-active-border-color:           #D4DCE7;
$input-active-label-color:            #D4DCE7;
$input-focused-border-color:          $primary;
$input-focused-label-color:           $primary;
$navbar-item-default-color:           $grey;

// [Breakpoints & Container Width]
$bp-xs: 0;
$bp-sm: 667px;
$bp-md: 1024px;
$bp-lg: 1280px;
$bp-xl: 1920px;

$cw-md: $bp-md;
$cw-lg: $bp-lg;
$cw-xl: 1822px;

// [Button Definitions]
$btn-border-radius: 30px;
$btn-font-weight: normal;
$btn-padding-y: 10px;
$btn-padding-x: 30px;
$btn-font-size: 16px;
$btn-line-height: 22px;

// [Form & Field Definitions]
$input-color: #040217;
$input-bg: #becee6;
$input-disabled-bg: rgba(0,0,0,.1);
$input-border-color: #090720;
$input-border-radius: 10px;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-padding-x: 0.875rem;
$input-padding-y: 0.845rem;
$input-line-height: 1.4;

$input-focus-bg: #fff !default;
$input-focus-border-color: #090720;
$input-placeholder-color: #325182;

// [Theme Configurations]
$theme-colors: (
  "primary": $primary,
  "danger": $danger,
  // V2 theme colors
  "v2-light": $v2-light,
  "v2-light-alt": $v2-light-alt,
  "v2-light-med": $v2-light-med,
  "v2-extra-light": $v2-extra-light,
  "v2-dark": $v2-dark,
  "v2-extra-dark": $v2-extra-dark,
);

$spacer: 5px !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * 1), // 5px
    2: ($spacer * 2), // 10px
    3: ($spacer * 3), // 15px
    4: ($spacer * 4), // 20px
    5: ($spacer * 5), // 25px;
    6: ($spacer * 6), // 30px;
    7: ($spacer * 7), // 35px;
    8: ($spacer * 8), // 40px;
    9: ($spacer * 9), // 45px;
    10: ($spacer * 10), // 50px;
    11: ($spacer * 11), // 55px;
    12: ($spacer * 12), // 60px;
    13: ($spacer * 13), // 65px;
    14: ($spacer * 14), // 70px;
    15: ($spacer * 15), // 75px;
    16: ($spacer * 16), // 80px;
    17: ($spacer * 17), // 85px;
    18: ($spacer * 18), // 90px;
    19: ($spacer * 19), // 95px;
    20: ($spacer * 20), // 100px;
    21: ($spacer * 21), // 105px;
  ),
  $spacers
);

$grid-gutter-width: 2 * 15px !default;

$grid-breakpoints: (
  xs: $bp-xs,
  sm: $bp-sm,
  md: $bp-md,
  lg: $bp-lg,
  xl: $bp-xl,
);

$container-max-widths: (
  md: $cw-md,
  lg: $cw-lg,
  xl: $cw-xl,
);

